import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import DialogCloseButton from "../../../../../../shared/components/DialogeCloseButton";
import NoAccessLockIcon from "../../../../../../shared/icons/NoAccessLockIcon";
import { useClientContext } from "../../../../../context/ClientContext";

interface Props {
  onClose: () => void;
}

const salesEmailAddress = "sales@entrilia.com";
const salesEmailSubject = "Fundraising Data Room";
const salesMailtoLink = `mailto:${salesEmailAddress}?subject=${salesEmailSubject}`;

const PublishToLiveRestrictedDialog = ({ onClose }: Props) => {
  const { fundraisingApp } = useClientContext();

  const allowedLiveFundraisings = fundraisingApp?.allowedLiveFundraisings ?? 0;

  const handleContactSalesClick = () => {
    window.open(salesMailtoLink, "_blank");
    setTimeout(onClose, 500);
  };

  return (
    <Dialog open onClose={onClose} PaperProps={{ sx: { width: "32.5rem" } }}>
      <DialogTitle>Publish</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <NoAccessLockIcon sx={{ width: 80, height: 80 }} />
          <Stack alignItems="center" spacing={1}>
            <Typography variant="h6">Publish not available</Typography>
            {allowedLiveFundraisings ? (
              <Typography color="text.secondary" textAlign="center">
                You've reached your limit of live fundraisings (
                <strong>
                  {allowedLiveFundraisings} of {allowedLiveFundraisings}
                </strong>{" "}
                used).
              </Typography>
            ) : (
              <Typography color="text.secondary" textAlign="center">
                You have no live fundraisings available.
              </Typography>
            )}
            <Typography color="text.secondary" textAlign="center">
              To publish another, please archive an existing fundraising or contact our sales team to get more.
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={onClose} autoFocus>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleContactSalesClick}>
          Contact Sales
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PublishToLiveRestrictedDialog;
