import { DataCollectionType, dataCollectionTypes } from "../../../api/mock/data-collections/dataCollectionsTypes";

export const dataCollectionsNavigationViews = ["all", ...dataCollectionTypes] as const;
export type DataCollectionsNavigationView = (typeof dataCollectionsNavigationViews)[number];

export const dataCollectionsTypeCaptionMap: Record<DataCollectionType, string> = {
  monthly_actuals: "Monthly Actuals Financials",
  budget: "Budget Financials",
  esg: "ESG Submission/Environmental",
};

export const getDataCollectionsNavigationViewCaption = (view: DataCollectionsNavigationView) =>
  view === "all" ? "Data Collections" : dataCollectionsTypeCaptionMap[view];
