import AddIcon from "@mui/icons-material/AddRounded";
import { Button, Typography } from "@mui/material";
import { useClientContext } from "../../../../context/ClientContext";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import { useFundraisingsPageContext } from "./FundraisingsPageContext";

interface Props {
  liveFundraisingsCount?: number;
}

const FundraisingsPageHeader = ({ liveFundraisingsCount }: Props) => {
  const { fundraisingApp } = useClientContext();
  const { hasEditPermissions, onCreate } = useFundraisingsPageContext();

  return (
    <GeneralPageHeader title="Fundraising" hideBorderLine>
      {fundraisingApp && liveFundraisingsCount !== undefined && (
        <Typography>
          <Typography component="span" color="text.secondary">
            Live fundraisings:
          </Typography>{" "}
          <Typography component="span" variant="subtitle2">
            {liveFundraisingsCount} of {fundraisingApp.allowedLiveFundraisings}
          </Typography>
        </Typography>
      )}
      {hasEditPermissions && (
        <Button variant="contained" startIcon={<AddIcon />} onClick={onCreate}>
          New Fundraising
        </Button>
      )}
    </GeneralPageHeader>
  );
};

export default FundraisingsPageHeader;
