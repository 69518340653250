import { Box, Divider } from "@mui/material";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { parseSearchParamWithFallback } from "../../../../shared/utilities/searchParamsHelper";
import DataCollectionsNavigation from "./DataCollectionsNavigation";
import { DataCollectionsNavigationView, dataCollectionsNavigationViews } from "./dataCollectionsPageTypes";
import DataCollectionsView from "./DataCollectionsView";

const DataCollectionsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedView, setSelectedView] = useState<DataCollectionsNavigationView>(
    parseSearchParamWithFallback(searchParams, "view", dataCollectionsNavigationViews, "all")
  );

  const handleViewChange = (view: DataCollectionsNavigationView) => {
    setSelectedView(view);
    setSearchParams({ view });
  };

  return (
    <Box display="flex" overflow="hidden" height="100%">
      <DataCollectionsNavigation selectedView={selectedView} onSelectedViewChange={handleViewChange} />
      <Divider flexItem orientation="vertical" />
      <DataCollectionsView view={selectedView} />
    </Box>
  );
};

export default DataCollectionsPage;
