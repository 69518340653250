import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useCallback, useMemo, useState } from "react";
import { withErrorHandling } from "../../../../../../../shared/api/axiosHelper";
import AutocompleteCreatable from "../../../../../../../shared/components/AutocompleteCreatable";
import DataLoadingFailed from "../../../../../../../shared/components/DataLoadingFailed";
import DialogCloseButton from "../../../../../../../shared/components/DialogeCloseButton";
import InlineLoader from "../../../../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../../shared/logging";
import { stringComparerBy } from "../../../../../../../shared/utilities/arrayHelper";
import adminApi from "../../../../../../api/adminApi";
import { PortalRole } from "../../../../../../api/types/accessTypes";
import { SelectOption } from "../../../../../common/filters/filterTypes";
import MultiselectAutocomplete from "../../../../fund-structure/common/MultiselectAutocomplete";
import { useFundraisingDetailsPageContext } from "../FundraisingDetailsPageContext";
import AccessEditor, { AccessValue } from "./AccessEditor";
import CreateContactDialog from "./CreateContactDialog";
import CreateInvestorDialog from "./CreateInvestorDialog";

interface Props {
  fundId: string;
  onSubmit: () => void;
  onClose: () => void;
}

interface InvestorAccessForm {
  selectedInvestorOption: InvestorOption | null;
  selectedContactOptions: ContactOption[];
}

interface InvestorOption {
  value: string;
  label: string;
  isNew?: boolean;
}

interface ContactOption {
  value: string;
  label: string;
  email: string;
  isNew?: boolean;
}

interface ChildDialogState {
  openDialog?: "create_investor" | "create_contact";
}

const isFormValid = (form: InvestorAccessForm, accessValue: AccessValue) =>
  form.selectedInvestorOption !== null &&
  form.selectedContactOptions.length > 0 &&
  accessValue.selectedCategoryIds.length > 0;

const createFundInvestor = withErrorHandling(adminApi.createFundInvestor);
const updateFundInvestor = withErrorHandling(adminApi.updateFundInvestor);

const AddFundraisingAccessDialog = ({ fundId, onSubmit, onClose }: Props) => {
  const { sendNotificationError } = useNotificationContext();
  const { fundraisingCategories } = useFundraisingDetailsPageContext();

  const getFundPermissions = useCallback(async () => adminApi.getFundPermissions(fundId), [fundId]);

  const [fundPermissions, fundPermissionsError, { isFetching: isFetchingFundPermissions }] =
    useFetch(getFundPermissions);

  const getInvestors = useCallback(
    async () => adminApi.searchInvestors({ fieldIds: [], includeFunds: false, includeContacts: false }),
    []
  );

  const [investors, investorsError, { isFetching: isFetchingInvestors }] = useFetch(getInvestors);

  const getContacts = useCallback(
    async () =>
      adminApi.searchContacts({ fieldIds: [], includeFunds: false, includeInvestors: false, includeInboxData: false }),
    []
  );

  const [contacts, contactsError, { isFetching: isFetchingContacts }] = useFetch(getContacts);

  const [investorAccessForm, setInvestorAccessForm] = useState<InvestorAccessForm>({
    selectedInvestorOption: null,
    selectedContactOptions: [],
  });

  const [accessValue, setAccessValue] = useState<AccessValue>({
    selectedCategoryIds: fundraisingCategories.map((c) => c.externalId),
  });

  const [isSaving, setSaving] = useState(false);
  const [childDialogState, setChildDialogState] = useState<ChildDialogState>({});

  const isFetching = isFetchingFundPermissions || isFetchingInvestors || isFetchingContacts;
  const fetchError = fundPermissionsError || investorsError || contactsError;

  const investorOptions = useMemo(
    () =>
      (investors?.items ?? [])
        .map(({ id, title }) => ({ value: id, label: title }))
        .sort(stringComparerBy((o) => o.label)),
    [investors?.items]
  );

  const contactOptions = useMemo(
    () =>
      (contacts?.items ?? [])
        .map(({ id, name, email }) => ({
          value: id,
          label: name,
          email,
        }))
        .sort(stringComparerBy((o) => o.label)),
    [contacts?.items]
  );

  const handleSave = async () => {
    if (fundPermissions === undefined) {
      return;
    }

    const { selectedInvestorOption } = investorAccessForm;
    if (selectedInvestorOption === null) {
      return;
    }

    const assignedRoles: PortalRole[] = ["LP_Member"];

    const newContacts = investorAccessForm.selectedContactOptions
      .filter((o) => o.isNew)
      .map((o) => ({
        name: o.label,
        email: o.email,
        roles: assignedRoles,
        externalCategoryIds: accessValue.selectedCategoryIds,
      }));

    const existingContacts = investorAccessForm.selectedContactOptions
      .filter((o) => !o.isNew)
      .map((o) => ({
        contactId: o.value,
        roles: assignedRoles,
        externalCategoryIds: accessValue.selectedCategoryIds,
      }));

    const fundInvestorId = fundPermissions.permissions.find(
      (p) => p.investorId === selectedInvestorOption.value
    )?.fundInvestorId;

    if (fundInvestorId && !selectedInvestorOption.isNew) {
      setSaving(true);

      const [, error] = await updateFundInvestor(fundInvestorId, {
        newContacts,
        existingContacts,
        removedContactIds: [],
      });

      setSaving(false);

      if (error) {
        logError(error, "[AddFundraisingAccessDialog] updateFundInvestor");
        sendNotificationError("Failed to update access");
        return;
      }

      onSubmit();
      onClose();
      return;
    }

    const investorId = selectedInvestorOption.isNew ? undefined : selectedInvestorOption.value;
    const newInvestorName = selectedInvestorOption.isNew ? selectedInvestorOption.label : undefined;

    setSaving(true);

    const [, error] = await createFundInvestor({
      fundId,
      investorId,
      newInvestorName,
      newContacts,
      existingContacts,
    });

    setSaving(false);

    if (error) {
      logError(error, "[AddFundraisingAccessDialog] createFundInvestor");
      sendNotificationError("Failed to add access");
      return;
    }

    onSubmit();
    onClose();
  };

  const handleAccessChange = (update: Partial<AccessValue>) => {
    setAccessValue((prev) => ({ ...prev, ...update }));
  };

  const handleInvestorSelected = (investorOption: SelectOption) => {
    setInvestorAccessForm((prev) => ({
      ...prev,
      selectedInvestorOption: investorOption,
      selectedContactOptions: [],
    }));
  };

  const handleContactsSelected = (contacts: ContactOption[]) => {
    setInvestorAccessForm((prev) => ({ ...prev, selectedContactOptions: contacts }));
  };

  const handleNewInvestorAdd = (name: string) => {
    setChildDialogState({});

    setInvestorAccessForm((prev) => ({
      ...prev,
      selectedInvestorOption: { value: `_new_${name}`, label: name, isNew: true },
    }));
  };

  const handleNewContactAdd = (name: string, email: string) => {
    setChildDialogState({});

    setInvestorAccessForm((prev) => ({
      ...prev,
      selectedContactOptions: [
        ...prev.selectedContactOptions,
        { value: `_new_${name}_${email}`, label: name, email: email, isNew: true },
      ],
    }));
  };

  const isValid = isFormValid(investorAccessForm, accessValue);

  const existingInvestorNames = investorOptions.map((o) => o.label);

  const existingContactNames = [
    ...contactOptions.map((o) => o.label),
    ...investorAccessForm.selectedContactOptions.map((o) => o.label),
  ];

  const existingContactEmails = [
    ...contactOptions.map((o) => o.email.toLowerCase()),
    ...investorAccessForm.selectedContactOptions.map((o) => o.email.toLowerCase()),
  ];

  return (
    <>
      <Dialog open onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Add New</DialogTitle>
        <DialogCloseButton onClick={onClose} />
        <Divider />

        <DialogContent>
          {isFetching && <InlineLoader />}
          {fetchError && <DataLoadingFailed title="Failed to load fund access data" />}
          {!isFetching && !fetchError && (
            <Stack spacing={4}>
              <Stack spacing={2}>
                <Typography color="text.secondary">Select an existing investor or create a new one.</Typography>
                <AutocompleteCreatable
                  withLazyRendering
                  noItemsText="No investors found"
                  onCreateOption={() => setChildDialogState({ openDialog: "create_investor" })}
                  openOnFocus
                  fullWidth
                  options={investorOptions}
                  isOptionEqualToValue={(opt, val) => opt.value === val.value}
                  renderInput={(params) => <TextField {...params} placeholder="Investor" />}
                  value={investorAccessForm.selectedInvestorOption}
                  onChange={(_, newValue) => {
                    if (newValue) {
                      handleInvestorSelected(newValue);
                    }
                  }}
                />
              </Stack>

              <Stack spacing={2}>
                <Typography color="text.secondary">
                  Select one or more existing contacts or create multiple new ones.
                </Typography>
                <MultiselectAutocomplete
                  options={contactOptions}
                  values={investorAccessForm.selectedContactOptions}
                  onSelected={handleContactsSelected}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  getOptionDescription={(option) => option.email}
                  placeholder="Contacts"
                  noItemsText="No contacts found"
                  onCreateOption={() => setChildDialogState({ openDialog: "create_contact" })}
                />
              </Stack>

              <AccessEditor value={accessValue} onChange={handleAccessChange} categories={fundraisingCategories} />
            </Stack>
          )}
        </DialogContent>

        <Divider />
        <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
          <Button variant="text" color="secondary" autoFocus onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={isSaving}
            onClick={handleSave}
            disabled={isFetching || !!fetchError || !isValid}
          >
            Add
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {childDialogState.openDialog === "create_investor" && (
        <CreateInvestorDialog
          onCreate={handleNewInvestorAdd}
          onClose={() => setChildDialogState({})}
          existingInvestorNames={existingInvestorNames}
        />
      )}

      {childDialogState.openDialog === "create_contact" && (
        <CreateContactDialog
          onCreate={handleNewContactAdd}
          onClose={() => setChildDialogState({})}
          existingContactNames={existingContactNames}
          existingContactEmails={existingContactEmails}
        />
      )}
    </>
  );
};

export default AddFundraisingAccessDialog;
