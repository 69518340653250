import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../../../shared/logging";
import {
  combineValidators,
  maxCharactersValidator,
  requiredValidator,
  uniqueValidator,
  ValidationResult,
} from "../../../../../../../shared/utilities/validators";
import adminApi from "../../../../../../api/adminApi";
import { Category } from "../../../../../../api/types/accessTypes";

interface Props {
  editedCategory?: Category;
  existingCategoryNames: string[];
  onUpdated: (updatedCategory: Category) => void;
  onClose: () => void;
}

interface FormState {
  name: string;
  validationResult: ValidationResult;
}

const maxNameLength = 50;

const createFundraisingCategory = withErrorHandling(adminApi.createFundraisingCategory);
const updateFundraisingCategory = withErrorHandling(adminApi.updateFundraisingCategory);

const EditCategoryDialog = ({ editedCategory, existingCategoryNames, onUpdated, onClose }: Props) => {
  const { sendNotificationError } = useNotificationContext();

  const [formState, setFormState] = useState<FormState>({
    name: editedCategory?.name ?? "",
    validationResult: { isValid: !!editedCategory?.name, error: "" },
  });

  const [isSaving, setSaving] = useState(false);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const validate = combineValidators(
      requiredValidator,
      maxCharactersValidator(maxNameLength),
      uniqueValidator("An access category with this name already exists", existingCategoryNames)
    );

    setFormState((prev) => ({
      ...prev,
      name: e.target.value,
      validationResult: validate(e.target.value),
    }));
  };

  const handleSubmit = async () => {
    setSaving(true);

    if (editedCategory) {
      const [updatedCategory, error] = await updateFundraisingCategory(editedCategory.id, { name: formState.name });
      setSaving(false);

      if (error) {
        logError(error, "[EditCategoryDialog] updateFundraisingCategory");
        sendNotificationError("Failed to update category");
      } else {
        onUpdated(updatedCategory);
      }

      return;
    }

    const [newCategory, error] = await createFundraisingCategory({ name: formState.name });
    setSaving(false);

    if (error) {
      logError(error, "[EditCategoryDialog] createFundraisingCategory");
      sendNotificationError("Failed to create category");
    } else {
      onUpdated(newCategory);
    }
  };

  const isNewCategory = !editedCategory;
  const isDirty = formState.name.trim() !== (editedCategory?.name ?? "");

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{isNewCategory ? "Add Category" : "Edit Category"}</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <TextField
          fullWidth
          label="Name"
          variant="outlined"
          value={formState.name}
          onChange={handleNameChange}
          helperText={formState.validationResult.error}
          error={!!formState.validationResult.error}
          inputProps={{ maxLength: maxNameLength }}
          InputProps={{
            endAdornment: (
              <Typography color="text.secondary" variant="caption">
                {maxNameLength - formState.name.length}
              </Typography>
            ),
          }}
        />
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <LoadingButton
          loading={isSaving}
          variant="contained"
          disabled={!formState.validationResult.isValid || !isDirty}
          onClick={handleSubmit}
        >
          {isNewCategory ? "Add" : "Save"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditCategoryDialog;
