import { createApiResponse } from "../../../../shared/api/axiosHelper";
import { ApiResponse } from "../../../../shared/api/types";
import { wait } from "../../../../shared/utilities/promiseHelper";
import { getAllDataCollections } from "./dataCollectionsMockStorage";
import { DataCollection, DataCollectionType } from "./dataCollectionsTypes";

const getDataCollections = async (type?: DataCollectionType): Promise<ApiResponse<DataCollection[]>> => {
  await wait(350);
  const items = type ? getAllDataCollections().filter((dc) => dc.type === type) : getAllDataCollections();
  return createApiResponse(items);
};

const dataCollectionsMockApi = {
  getDataCollections,
};

export default dataCollectionsMockApi;
