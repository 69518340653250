export const dataCollectionTypes = ["monthly_actuals", "budget", "esg"] as const;
export type DataCollectionType = (typeof dataCollectionTypes)[number];

export interface DataCollection {
  id: string;
  number: string;
  portfolioCompanyName: string;
  status: DataCollectionStatus;
  type: DataCollectionType;
  reportingDate: string;
  dueDate: string;
  submittedByUserName?: string;
  submittedAt?: string;
  reviewedByUserName?: string;
  reviewedAt?: string;
}

export type DataCollectionStatus = "Pending" | "PendingApproval" | "Completed";
