import CalendarIcon from "@mui/icons-material/CalendarTodayRounded";
import PersonIcon from "@mui/icons-material/PersonOutlineRounded";
import { Alert, AlertTitle, Box, Container, Stack, Typography } from "@mui/material";
import UserAvatar from "../../../../../shared/components/UserAvatar";
import { distinctBy } from "../../../../../shared/utilities/arrayHelper";
import { convertISODateTimeHumanized } from "../../../../../shared/utilities/dateUtils";
import { autoFormatCamelCase } from "../../../../../shared/utilities/stringHelper";
import { FileInfo } from "../../../../api/adminApi";
import { DataImportInfo } from "../../../../api/types/dataImportTypes";
import SelectIcon from "../../../../icons/SelectIcon";
import { dataImportTargetCaptionMap } from "../../../../utilities/enumCaptions";
import ImportSourceLabel from "../ImportSourceLabel";
import ImportDataDetailsAttribute from "./ImportDataDetailsAttribute";
import ImportDataStatTile from "./ImportDataStatTile";
import ImportInProgressAlert from "./ImportInProgressAlert";
import ImportSourceFilesTable from "./ImportSourceFilesTable";

interface Props {
  dataImport: DataImportInfo;
  sourceFiles: FileInfo[];
  onRefresh: () => void;
}

const formatTimestamp = (isoDateString: string) =>
  isoDateString ? convertISODateTimeHumanized(isoDateString, { thresholdMinutes: 60 }) || "-" : "-";

const ImportDataDetailsSection = ({ dataImport, sourceFiles, onRefresh }: Props) => {
  const processedRecordsCount = dataImport.applicationResult?.processedRecords;

  const skippedRecordsCount = dataImport.applicationResult
    ? dataImport.applicationResult.skippedInvalidRecords +
      dataImport.applicationResult.excludedRecords +
      dataImport.applicationResult.failedRecords
    : undefined;

  return (
    <Container maxWidth="md" disableGutters>
      {dataImport.state === "InProgress" && (
        <Box mt={2.5} width="100%">
          <ImportInProgressAlert onRefresh={onRefresh} />
        </Box>
      )}

      {dataImport.state === "Failed" && (
        <Box mt={2.5} width="100%">
          <Alert severity="error">
            <AlertTitle>Import failed</AlertTitle>
            <Typography>{dataImport.applicationResult?.error || "Unknown error"}</Typography>
          </Alert>
        </Box>
      )}

      <Box py={2} mt={2.5}>
        <Typography variant="subtitle1">Summary</Typography>
      </Box>
      <Stack direction="row" spacing={2} width="100%" useFlexGap>
        <ImportDataStatTile dataImportId={dataImport.id} title="Imported" value={processedRecordsCount} />
        <ImportDataStatTile dataImportId={dataImport.id} title="Skipped or excluded rows" value={skippedRecordsCount} />
      </Stack>

      <Box py={2} mt={2.5}>
        <Typography variant="subtitle1">Import Details</Typography>
      </Box>
      <Stack spacing={0.5} width="100%">
        <ImportDataDetailsAttribute Icon={SelectIcon} label="Entity">
          {distinctBy(dataImport.files, (f) => f.importObjectTypeTarget).map(({ importObjectTypeTarget }) => (
            <Typography key={importObjectTypeTarget}>{autoFormatCamelCase(importObjectTypeTarget)}</Typography>
          ))}
        </ImportDataDetailsAttribute>

        <ImportDataDetailsAttribute Icon={SelectIcon} label="Data">
          {distinctBy(dataImport.files, (f) => f.importTypeTarget).map(({ importTypeTarget }) => (
            <Typography key={importTypeTarget}>{dataImportTargetCaptionMap[importTypeTarget]}</Typography>
          ))}
        </ImportDataDetailsAttribute>

        <ImportDataDetailsAttribute Icon={SelectIcon} label="Source">
          <ImportSourceLabel dataImport={dataImport} />
        </ImportDataDetailsAttribute>

        <ImportDataDetailsAttribute Icon={PersonIcon} label="Created by">
          <Stack spacing={1.25} direction="row" alignItems="center">
            <UserAvatar size="small" userName={dataImport.createdByUserName} />
            <Typography>{dataImport.createdByUserName}</Typography>
          </Stack>
        </ImportDataDetailsAttribute>

        <ImportDataDetailsAttribute Icon={CalendarIcon} label="Created at">
          <Typography>{formatTimestamp(dataImport.createdAt)}</Typography>
        </ImportDataDetailsAttribute>

        {dataImport.applicationResult && (
          <>
            <ImportDataDetailsAttribute Icon={PersonIcon} label="Import started by">
              {dataImport.applicationResult.createdByUserName ? (
                <Stack spacing={1.25} direction="row" alignItems="center">
                  <UserAvatar size="small" userName={dataImport.applicationResult.createdByUserName} />
                  <Typography>{dataImport.createdByUserName}</Typography>
                </Stack>
              ) : (
                <Typography>-</Typography>
              )}
            </ImportDataDetailsAttribute>

            <ImportDataDetailsAttribute Icon={CalendarIcon} label="Import started at">
              <Typography>{formatTimestamp(dataImport.applicationResult.startedAt)}</Typography>
            </ImportDataDetailsAttribute>

            <ImportDataDetailsAttribute Icon={CalendarIcon} label="Import finished at">
              <Typography>{formatTimestamp(dataImport.applicationResult.finishedAt ?? "")}</Typography>
            </ImportDataDetailsAttribute>
          </>
        )}

        {dataImport.rollbackResult && (
          <>
            <ImportDataDetailsAttribute Icon={PersonIcon} label="Rollback started by">
              {dataImport.rollbackResult.createdByUserName ? (
                <Stack spacing={1.25} direction="row" alignItems="center">
                  <UserAvatar size="small" userName={dataImport.rollbackResult.createdByUserName} />
                  <Typography>{dataImport.createdByUserName}</Typography>
                </Stack>
              ) : (
                <Typography>-</Typography>
              )}
            </ImportDataDetailsAttribute>

            <ImportDataDetailsAttribute Icon={CalendarIcon} label="Rollback started at">
              <Typography>{formatTimestamp(dataImport.rollbackResult.startedAt)}</Typography>
            </ImportDataDetailsAttribute>

            <ImportDataDetailsAttribute Icon={CalendarIcon} label="Rollback finished at">
              <Typography>{formatTimestamp(dataImport.rollbackResult.finishedAt ?? "")}</Typography>
            </ImportDataDetailsAttribute>
          </>
        )}
      </Stack>

      <Box py={2} mt={2.5}>
        <Typography variant="subtitle1">Source Files</Typography>
      </Box>
      <ImportSourceFilesTable importId={dataImport.id} sourceFiles={sourceFiles} />
    </Container>
  );
};

export default ImportDataDetailsSection;
