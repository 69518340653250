import { Box, Divider, Grid, Stack } from "@mui/material";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import useDebounce from "../../../../../../shared/hooks/useDebounce";
import useFetch from "../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../shared/logging";
import { SortCompareFn, stringComparerBy } from "../../../../../../shared/utilities/arrayHelper";
import adminApi, { InvoiceEntry, InvoiceProcessingStatus } from "../../../../../api/adminApi";
import { ObjectFieldValues } from "../../../../../api/types/objectTypes";
import { useClientContext } from "../../../../../context/ClientContext";
import { useLocalization } from "../../../../../hooks/useLocalization";
import { pageRoutes } from "../../../../../routes";
import DocumentPreview from "../../../../common/document-preview/DocumentPreview";
import InvoiceDetailsHeader from "./InvoiceDetailsHeader";
import InvoiceFieldsContainer from "./InvoiceFieldsContainer";
import CreateTransactionDialog from "./dialogs/CreateTransactionDialog";
import RejectInvoiceDialog from "./dialogs/RejectInvoiceDialog";
import CreateVendorDialog from "./dialogs/create-vendor/CreateVendorDialog";
import {
  InvoiceDetailsLine,
  InvoiceDetailsPageState,
  InvoiceDetailsState,
  buildSaveInvoicePayload,
  getInitialState,
  isFieldLowConfident,
  validateForm,
} from "./invoiceDetailsState";

const saveInvoice = withErrorHandling(adminApi.updateInvoice);
const sortEntriesByCaptionComparer: SortCompareFn<InvoiceEntry> = stringComparerBy((r) => r.caption.toLowerCase());

const InvoiceDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { clientCode, dictionaries, updateMenuBadges, hasPermissions } = useClientContext();
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const canManageInvoices = hasPermissions(["ManageExpenses"]);

  const { invoices: locale } = useLocalization();

  const [isLoading, setLoading] = useState(false);
  const [invoiceFormState, setInvoiceFormState] = useState<InvoiceDetailsPageState>(getInitialState());
  const [showCreateTransactionDialog, setShowCreateTransactionDialog] = useState(false);
  const [showRejectInvoiceDialog, setShowRejectInvoiceDialog] = useState(false);
  const [showCreateVendorDialog, setShowCreateVendorDialog] = useState(false);

  const fetchInvoice = useCallback(() => adminApi.getInvoiceDetails(id || ""), [id]);
  const fetchFileInfo = useCallback(() => adminApi.getInvoiceDownloadFileInfo(id || ""), [id]);

  const [invoiceFetchResult, error] = useFetch(fetchInvoice, (data) => {
    setInvoiceFormState((current) => {
      const { vendors, entities, glAccounts, deals, ...others } = data;
      const initialStateWithNoErrors: InvoiceDetailsPageState = {
        ...current,
        form: validateForm({
          ...current.form,
          ...others,
          lines: (others.lines || []).map((line) => ({
            amount: line.amount,
            description: line.description,
            currencyCode: others.currencyCode,
            id: line.id,
            account:
              line.glAccountNo && line.glAccountName
                ? {
                    no: line.glAccountNo,
                    name: line.glAccountName,
                  }
                : undefined,
            dealId: line.dealId,
          })),
          confidenceWarnings: {
            amount: isFieldLowConfident(others.fieldsConfidence?.amount),
            dueDate: isFieldLowConfident(others.fieldsConfidence?.dueDate),
            postDate: isFieldLowConfident(others.fieldsConfidence?.postDate),
            lines: (others.fieldsConfidence?.lines || []).map((line) => ({
              id: line.id,
              description: isFieldLowConfident(line.description),
              amount: isFieldLowConfident(line.amount),
            })),
          },
        }),
        vendors: vendors || [],
        entities: entities || [],
        deals: deals || [],
        glAccounts: glAccounts || [],
        initialData: {
          vendor: others.vendor?.caption,
          dueDate: others.dueDate,
          entity: others.entity?.caption,
          status: others.status,
          businessCentralTransactionNo: others.businessCentralTransactionNo,
          transactionTypeCode: others.transactionTypeCode,
        },
      };
      initialStateWithNoErrors.form.errors = {};
      return initialStateWithNoErrors;
    });
  });

  const handleSave = async () => {
    setLoading(true);
    const [, error] = await saveInvoice({
      invoiceId: id || "",
      payload: buildSaveInvoicePayload(invoiceFormState),
    });
    if (error) {
      sendNotificationError(locale.saving_error);
    } else {
      sendNotification(locale.saved);
      setInvoiceFormState((current) => ({
        ...current,
        form: {
          ...current.form,
          isDirty: false,
          confidenceWarnings: {
            lines: [],
          },
        },
      }));
    }
    setLoading(false);
  };

  const handleBackClick = () => {
    navigate(`/${clientCode}/${pageRoutes.expenseManagement}/${pageRoutes.invoices}`);
  };

  const handleFormChange = (newForm: InvoiceDetailsState) => {
    setInvoiceFormState((current) => ({ ...current, form: { ...validateForm(newForm), isDirty: true } }));
  };

  const handleLineAdd = () => {
    const currentErrors = invoiceFormState.form.errors;

    return setInvoiceFormState((current) => {
      const newLineId = current.form.lines.length > 0 ? Math.max(...current.form.lines.map((l) => l.id)) + 1 : 0;
      const validatedFormWithUntouchedErrors = validateForm({
        ...current.form,
        isDirty: true,
        lines: [...current.form.lines, { id: newLineId }],
      });
      validatedFormWithUntouchedErrors.errors = currentErrors;
      return {
        ...current,
        form: validatedFormWithUntouchedErrors,
      };
    });
  };

  const handleLineDelete = useDebounce((lineId: number) => {
    setInvoiceFormState((current) => {
      const currentErrors = current.form.errors;
      const validatedFormWithUntouchedErrors = validateForm({
        ...current.form,
        isDirty: true,
        lines: current.form.lines.filter((line) => line.id !== lineId),
      });
      validatedFormWithUntouchedErrors.errors = currentErrors;
      return {
        ...current,
        form: validatedFormWithUntouchedErrors,
      };
    });
  }, 200);

  const handleLineChange = (updatedLine: InvoiceDetailsLine) => {
    const prevLine = invoiceFormState.form.lines.find((line) => line.id === updatedLine.id);
    if (!prevLine) {
      return;
    }

    setInvoiceFormState((current) => {
      const newLines = current.form.lines.map((l) => (l.id === updatedLine.id ? updatedLine : l));
      return {
        ...current,
        form: validateForm({
          ...current.form,
          isDirty: true,
          lines: newLines,
          confidenceWarnings: {
            ...current.form.confidenceWarnings,
            lines: current.form.confidenceWarnings.lines.map((line) => {
              if (line.id === updatedLine.id) {
                if (prevLine.amount !== updatedLine.amount) {
                  return {
                    ...line,
                    amount: undefined,
                  };
                }
                if (prevLine.description !== updatedLine.description) {
                  return {
                    ...line,
                    description: undefined,
                  };
                }
              }
              return line;
            }),
          },
        }),
      };
    });
  };

  if (error) {
    logError(error, "InvoiceDetailsPage");
    return <DataLoadingFailed title="Could not load invoice details" />;
  }

  if (!invoiceFetchResult) {
    return <InlineLoader text={"Loading the invoice data..."} />;
  }

  const handleSaved = () => {
    setShowCreateTransactionDialog(false);
    setInvoiceFormState((prev) => ({
      ...prev,
      initialData: {
        ...prev.initialData,
        status: InvoiceProcessingStatus.TransactionCreated,
        entity: invoiceFormState.form.entity?.caption,
        vendor: invoiceFormState.form.vendor?.caption,
        dueDate: invoiceFormState.form.dueDate,
      },
      form: {
        ...prev.form,
        confidenceWarnings: {
          lines: [],
        },
      },
    }));
    updateMenuBadges();
  };

  const handleRejected = () => {
    setShowRejectInvoiceDialog(false);
    setInvoiceFormState((prev) => ({
      ...prev,
      initialData: {
        ...prev.initialData,
        status: InvoiceProcessingStatus.Rejected,
      },
      form: {
        ...prev.form,
        confidenceWarnings: {
          lines: [],
        },
      },
    }));
    updateMenuBadges();
  };

  const handleCreateVendor = () => {
    setShowCreateVendorDialog(true);
  };

  const handleVendorCreated = (vendor: ObjectFieldValues) => {
    setShowCreateVendorDialog(false);
    const newVendor: InvoiceEntry = { caption: vendor.name, id: vendor.id };
    setInvoiceFormState((prev) => ({
      ...prev,
      vendors: [...prev.vendors, newVendor].sort(sortEntriesByCaptionComparer),
      form: validateForm({ ...invoiceFormState.form, vendor: newVendor, isDirty: true }),
    }));
  };

  return (
    <Stack sx={{ height: "100%", overflowY: "hidden" }}>
      {invoiceFormState.form && (
        <>
          <Box px={3}>
            <InvoiceDetailsHeader
              onBackClick={handleBackClick}
              data={invoiceFormState.initialData}
              onSaveClick={handleSave}
              onApproveClick={() => setShowCreateTransactionDialog(true)}
              onRejectClick={() => setShowRejectInvoiceDialog(true)}
              loading={isLoading}
              hideInvoiceManagementControls={invoiceFormState.initialData.status !== InvoiceProcessingStatus.Pending}
              hideOpenTransaction={invoiceFormState.initialData.status === InvoiceProcessingStatus.Pending}
              disabledApproveButton={!invoiceFormState.form.isValid}
              disabledSaveButton={!invoiceFormState.form.isDirty}
              readonly={!canManageInvoices}
            />
          </Box>
          <Divider />
        </>
      )}
      <Grid container height="100%">
        <Grid item xs={7} sx={(theme) => ({ borderRight: "1px solid", borderColor: theme.palette.divider })}>
          <InvoiceFieldsContainer
            form={invoiceFormState.form}
            onChange={handleFormChange}
            vendors={invoiceFormState.vendors}
            entities={invoiceFormState.entities}
            deals={invoiceFormState.deals}
            currencies={dictionaries.currencies}
            glAccounts={invoiceFormState.glAccounts}
            onLineAdd={handleLineAdd}
            onLineDelete={handleLineDelete}
            onLineChange={handleLineChange}
            readonly={invoiceFormState.initialData.status !== InvoiceProcessingStatus.Pending || !canManageInvoices}
            onCreateVendor={handleCreateVendor}
          />
        </Grid>
        <Grid item xs={5}>
          <DocumentPreview getFileDownloadInfoCallback={fetchFileInfo} />
        </Grid>
      </Grid>
      <CreateTransactionDialog
        open={showCreateTransactionDialog}
        onCancel={() => setShowCreateTransactionDialog(false)}
        updateRequest={{
          invoiceId: id || "",
          payload: buildSaveInvoicePayload(invoiceFormState),
        }}
        onSaved={handleSaved}
      />
      <RejectInvoiceDialog
        open={showRejectInvoiceDialog}
        invoiceId={id || ""}
        onCancel={() => setShowRejectInvoiceDialog(false)}
        onRejected={handleRejected}
      />
      <CreateVendorDialog
        open={showCreateVendorDialog}
        onCancel={() => setShowCreateVendorDialog(false)}
        onSaved={handleVendorCreated}
        currencyCodeValues={dictionaries.currencies}
        vendors={invoiceFormState.vendors}
      />
    </Stack>
  );
};

export default InvoiceDetailsPage;
