import { addHours, addMinutes, endOfMonth, startOfDay, subDays, subMonths } from "date-fns";
import { DataCollection } from "./dataCollectionsTypes";

const users = ["Chris Redd", "Laura Wranegard", "Wade Warren", "Cameron Williamson", "Kristin Watson"] as const;

const baseDate = startOfDay(new Date());

export const getAllDataCollections = (): DataCollection[] => [
  {
    id: "d6e2ca09-0935-40fe-88fa-98b438759ce1",
    number: "7495758",
    portfolioCompanyName: "Fresenius Medical Care AG",
    status: "Pending",
    type: "monthly_actuals",
    reportingDate: endOfMonth(subMonths(baseDate, 2)).toISOString(),
    dueDate: endOfMonth(baseDate).toISOString(),
  },
  {
    id: "e5abbd7d-6071-4a47-b72d-ba00aced2406",
    number: "7495759",
    portfolioCompanyName: "GEA Group AG",
    status: "Pending",
    type: "monthly_actuals",
    reportingDate: endOfMonth(subMonths(baseDate, 2)).toISOString(),
    dueDate: endOfMonth(baseDate).toISOString(),
  },
  {
    id: "426d49e1-f3a2-4f33-a6d2-35a163759b82",
    number: "7495760",
    portfolioCompanyName: "LEG Immobilien SE",
    status: "PendingApproval",
    type: "monthly_actuals",
    reportingDate: endOfMonth(subMonths(baseDate, 2)).toISOString(),
    dueDate: endOfMonth(baseDate).toISOString(),
    submittedByUserName: users[1],
    submittedAt: addMinutes(addHours(subDays(baseDate, 1), 11), 10).toISOString(),
  },
  {
    id: "f2a675e3-536c-472a-a6dc-6e40157be9c2",
    number: "7495761",
    portfolioCompanyName: "Deutsche Lufthansa AG",
    status: "Pending",
    type: "budget",
    reportingDate: endOfMonth(subMonths(baseDate, 2)).toISOString(),
    dueDate: endOfMonth(baseDate).toISOString(),
  },
  {
    id: "89bd6ea4-539f-45ac-9606-f25ddffac745",
    number: "7495762",
    portfolioCompanyName: "Scout24 SE",
    status: "PendingApproval",
    type: "monthly_actuals",
    reportingDate: endOfMonth(subMonths(baseDate, 2)).toISOString(),
    dueDate: endOfMonth(baseDate).toISOString(),
    submittedByUserName: users[2],
    submittedAt: addMinutes(addHours(subDays(baseDate, 1), 12), 23).toISOString(),
  },
  {
    id: "df47ab1c-4e31-46de-ad2d-48376322f653",
    number: "7495763",
    portfolioCompanyName: "Nemetschek",
    status: "Pending",
    type: "monthly_actuals",
    reportingDate: endOfMonth(subMonths(baseDate, 2)).toISOString(),
    dueDate: endOfMonth(baseDate).toISOString(),
  },
  {
    id: "0232a186-097a-4d55-b89e-e9290ab5ef12",
    number: "7495764",
    portfolioCompanyName: "Knorr-Bremse AG",
    status: "Completed",
    type: "budget",
    reportingDate: endOfMonth(subMonths(baseDate, 2)).toISOString(),
    dueDate: endOfMonth(baseDate).toISOString(),
    submittedByUserName: users[2],
    submittedAt: addMinutes(addHours(subDays(baseDate, 2), 13), 43).toISOString(),
    reviewedByUserName: users[4],
    reviewedAt: addMinutes(addHours(subDays(baseDate, 0), 16), 29).toISOString(),
  },
  {
    id: "c1671699-e56b-459d-a0f1-b0c408ed6961",
    number: "7495765",
    portfolioCompanyName: "Puma SE",
    status: "Completed",
    type: "esg",
    reportingDate: endOfMonth(subMonths(baseDate, 2)).toISOString(),
    dueDate: endOfMonth(baseDate).toISOString(),
    submittedByUserName: users[1],
    submittedAt: addMinutes(addHours(subDays(baseDate, 3), 11), 32).toISOString(),
    reviewedByUserName: users[3],
    reviewedAt: addMinutes(addHours(subDays(baseDate, 1), 14), 11).toISOString(),
  },
  {
    id: "5ac0ee78-4347-40ba-847b-4071fb966d98",
    number: "7495766",
    portfolioCompanyName: "CTS Eventim AG & Co. KGaA",
    status: "PendingApproval",
    type: "monthly_actuals",
    reportingDate: endOfMonth(subMonths(baseDate, 2)).toISOString(),
    dueDate: endOfMonth(baseDate).toISOString(),
    submittedByUserName: users[2],
    submittedAt: addMinutes(addHours(subDays(baseDate, 3), 17), 7).toISOString(),
  },
  {
    id: "142d8728-a924-4d60-b3df-6affc0becec3",
    number: "7495767",
    portfolioCompanyName: "Evonik Industries AG",
    status: "Completed",
    type: "budget",
    reportingDate: endOfMonth(subMonths(baseDate, 2)).toISOString(),
    dueDate: endOfMonth(baseDate).toISOString(),
    submittedByUserName: users[0],
    submittedAt: addMinutes(addHours(subDays(baseDate, 4), 12), 55).toISOString(),
    reviewedByUserName: users[3],
    reviewedAt: addMinutes(addHours(subDays(baseDate, 3), 14), 15).toISOString(),
  },
  {
    id: "2e869cd8-269c-49f4-8acd-7e0c5142fbbb",
    number: "7495768",
    portfolioCompanyName: "Fresenius Medical Care AG",
    status: "Completed",
    type: "esg",
    reportingDate: endOfMonth(subMonths(baseDate, 3)).toISOString(),
    dueDate: endOfMonth(subMonths(baseDate, 1)).toISOString(),
    submittedByUserName: users[1],
    submittedAt: addMinutes(addHours(subDays(baseDate, 4), 12), 31).toISOString(),
    reviewedByUserName: users[4],
    reviewedAt: addMinutes(addHours(subDays(baseDate, 3), 11), 11).toISOString(),
  },
  {
    id: "258f6115-4710-47ff-8a0e-2f14d592d81a",
    number: "7495769",
    portfolioCompanyName: "LEG Immobilien SE",
    status: "Completed",
    type: "esg",
    reportingDate: endOfMonth(subMonths(baseDate, 3)).toISOString(),
    dueDate: endOfMonth(subMonths(baseDate, 1)).toISOString(),
    submittedByUserName: users[2],
    submittedAt: addMinutes(addHours(subDays(baseDate, 5), 1), 40).toISOString(),
    reviewedByUserName: users[4],
    reviewedAt: addMinutes(addHours(subDays(baseDate, 3), 1), 12).toISOString(),
  },
  {
    id: "176d212f-3abf-4ce7-8f06-73b270b8005c",
    number: "7495770",
    portfolioCompanyName: "GEA Group AG",
    status: "PendingApproval",
    type: "budget",
    reportingDate: endOfMonth(subMonths(baseDate, 3)).toISOString(),
    dueDate: endOfMonth(subMonths(baseDate, 1)).toISOString(),
    submittedByUserName: users[2],
    submittedAt: addMinutes(addHours(subDays(baseDate, 7), 9), 21).toISOString(),
  },
  {
    id: "0368c335-eaa1-4900-ab31-b2f9cc7ab779",
    number: "7495771",
    portfolioCompanyName: "Deutsche Lufthansa AG",
    status: "PendingApproval",
    type: "monthly_actuals",
    reportingDate: endOfMonth(subMonths(baseDate, 3)).toISOString(),
    dueDate: endOfMonth(subMonths(baseDate, 1)).toISOString(),
    submittedByUserName: users[2],
    submittedAt: addMinutes(addHours(subDays(baseDate, 8), 12), 4).toISOString(),
  },
  {
    id: "e7c42aff-7a20-46e0-a620-888d556f38aa",
    number: "7495772",
    portfolioCompanyName: "CTS Eventim AG & Co. KGaA",
    status: "Completed",
    type: "budget",
    reportingDate: endOfMonth(subMonths(baseDate, 3)).toISOString(),
    dueDate: endOfMonth(subMonths(baseDate, 1)).toISOString(),
    submittedByUserName: users[2],
    submittedAt: addMinutes(addHours(subDays(baseDate, 9), 11), 17).toISOString(),
    reviewedByUserName: users[3],
    reviewedAt: addMinutes(addHours(subDays(baseDate, 8), 5), 57).toISOString(),
  },
  {
    id: "2e69883d-7538-4b80-99ef-ee8fa51167cf",
    number: "7495773",
    portfolioCompanyName: "Evonik Industries AG",
    status: "Completed",
    type: "esg",
    reportingDate: endOfMonth(subMonths(baseDate, 3)).toISOString(),
    dueDate: endOfMonth(subMonths(baseDate, 1)).toISOString(),
    submittedByUserName: users[0],
    submittedAt: addMinutes(addHours(subDays(baseDate, 4), 9), 24).toISOString(),
    reviewedByUserName: users[4],
    reviewedAt: addMinutes(addHours(subDays(baseDate, 3), 8), 19).toISOString(),
  },
];
