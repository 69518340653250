import { dataCollectionTypes } from "../../../api/mock/data-collections/dataCollectionsTypes";
import VerticalNavigationMenuItem from "../../common/navigation/VerticalNavigationMenuItem";
import VerticalNavigationSection from "../../common/navigation/VerticalNavigationSection";
import VerticalNavigationWrapper from "../../common/navigation/VerticalNavigationWrapper";
import { DataCollectionsNavigationView, getDataCollectionsNavigationViewCaption } from "./dataCollectionsPageTypes";

interface Props {
  selectedView: DataCollectionsNavigationView;
  onSelectedViewChange: (view: DataCollectionsNavigationView) => void;
}

const DataCollectionsNavigation = ({ selectedView, onSelectedViewChange }: Props) => {
  return (
    <VerticalNavigationWrapper>
      <VerticalNavigationSection title="Menu">
        <VerticalNavigationMenuItem
          text={getDataCollectionsNavigationViewCaption("all")}
          selected={selectedView === "all"}
          onClick={() => onSelectedViewChange("all")}
        />
      </VerticalNavigationSection>

      <VerticalNavigationSection title="Type">
        {dataCollectionTypes.map((type) => (
          <VerticalNavigationMenuItem
            key={type}
            text={getDataCollectionsNavigationViewCaption(type)}
            selected={selectedView === type}
            onClick={() => onSelectedViewChange(type)}
          />
        ))}
      </VerticalNavigationSection>
    </VerticalNavigationWrapper>
  );
};

export default DataCollectionsNavigation;
