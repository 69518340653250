import { ApiResponse } from "../../../../shared/api/types";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import PdfPreview from "../../../../shared/components/previewFile/pdfViewer/PdfPreview";
import useFetch from "../../../../shared/hooks/useFetch";
import { logError } from "../../../../shared/logging";
import { isImageFile, isPdfFile } from "../../../../shared/utilities/fileHelper";
import { FileDownloadInfo } from "../../../api/adminApi";
import ImagePreview from "./ImagePreview";
import NotPossibleDocumentPreview from "./NotPossibleDocumentPreview";

interface Props {
  getFileDownloadInfoCallback: () => Promise<ApiResponse<FileDownloadInfo>>;
}

const DocumentPreview = ({ getFileDownloadInfoCallback }: Props) => {
  const [fileInfo, error, { isFetching }] = useFetch(getFileDownloadInfoCallback);
  if (error) {
    logError(error, "[DocumentPreview] getFileDownloadInfoCallback");
    return <NotPossibleDocumentPreview getFileDownloadInfoCallback={getFileDownloadInfoCallback} />;
  }

  if (isFetching || fileInfo === undefined) {
    return <InlineLoader />;
  }

  if (isPdfFile(fileInfo.fileName)) {
    return <PdfPreview fileSrc={fileInfo.downloadUrl} fileName={fileInfo.fileName} />;
  }

  if (isImageFile(fileInfo.fileName)) {
    return <ImagePreview fileInfo={fileInfo} getFileDownloadInfoCallback={getFileDownloadInfoCallback} />;
  }

  return <NotPossibleDocumentPreview getFileDownloadInfoCallback={getFileDownloadInfoCallback} />;
};

export default DocumentPreview;
