import { Box, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useCallback, useReducer } from "react";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import useFetch from "../../../../shared/hooks/useFetch";
import dataCollectionsMockApi from "../../../api/mock/data-collections/dataCollectionsMockApi";
import { DataCollection } from "../../../api/mock/data-collections/dataCollectionsTypes";
import { useClientContext } from "../../../context/ClientContext";
import { FilterContextProvider } from "../../common/filters/FilterContext";
import FiltersPanel from "../../common/filters/FiltersPanel";
import { createReducer, filterRecords, getInitialState } from "../../common/filters/filterState";
import DataCollectionsGrid from "./DataCollectionsGrid";
import { getFilterDefinitions, getSearchDefinition } from "./dataCollectionsGridFilters";
import { DataCollectionsNavigationView, getDataCollectionsNavigationViewCaption } from "./dataCollectionsPageTypes";

interface Props {
  view: DataCollectionsNavigationView;
}

const DataCollectionsView = ({ view }: Props) => {
  const { clientCode } = useClientContext();

  const [filterState, dispatchFilters] = useReducer(
    createReducer<DataCollection>(),
    getInitialState(`${clientCode}_DataCollection_filter_v1`, getFilterDefinitions(), getSearchDefinition(), [])
  );

  const getDataCollections = useCallback(
    () => dataCollectionsMockApi.getDataCollections(view === "all" ? undefined : view),
    [view]
  );

  const [dataCollections, fetchError, { isFetching }] = useFetch(getDataCollections);

  if (fetchError) {
    return <DataLoadingFailed title="Could not load data collections" />;
  }

  const allDataCollections = dataCollections ?? [];
  const filteredDataCollections = filterRecords(filterState, allDataCollections);

  return (
    <FilterContextProvider
      filterState={filterState}
      dispatchFilters={dispatchFilters}
      allRowsForSelectOptions={allDataCollections}
    >
      <Box flex={1} display="flex" flexDirection="column">
        <Box py={2} px={3} borderBottom={1} borderColor="divider">
          <Typography variant="h6">{getDataCollectionsNavigationViewCaption(view)}</Typography>
        </Box>

        <Stack width="100%" spacing={2} pt={2.5} px={3} flex={1}>
          <FiltersPanel
            totalCount={allDataCollections.length}
            recordCount={filteredDataCollections.length}
            isLoading={isFetching}
          />

          <DataCollectionsGrid key={view} dataCollections={filteredDataCollections} isLoading={isFetching} />
        </Stack>
      </Box>
    </FilterContextProvider>
  );
};

export default DataCollectionsView;
