import { useCallback, useState } from "react";
import { Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { createApiResponse } from "../../../../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../shared/logging";
import { numberComparerBy } from "../../../../../../shared/utilities/arrayHelper";
import { parseSearchParamWithFallback } from "../../../../../../shared/utilities/searchParamsHelper";
import adminApi from "../../../../../api/adminApi";
import { Fundraising } from "../../../../../api/types/fundraisingTypes";
import { useClientContext } from "../../../../../context/ClientContext";
import PageTabs from "../../../../common/PageTabs";
import FundraisingDialogs from "../dialogs/FundraisingDialogs";
import {
  DetailsPageTab,
  DialogState,
  FundraisingStatusTransition,
  detailsPageTabLabels,
  detailsPageTabs,
} from "../fundraisingsPageTypes";
import FundraisingAccessConfig from "./fundraising-access/FundraisingAccessConfig";
import FundraisingDocuments from "./fundraising-documents/FundraisingDocuments";
import FundraisingCategoriesConfig from "./FundraisingCategoriesConfig";
import FundraisingContentConfig from "./FundraisingContentConfig";
import { FundraisingDetailsPageContextProvider } from "./FundraisingDetailsPageContext";
import FundraisingDetailsPageHeader from "./FundraisingDetailsPageHeader";
import FundraisingDetailsTabPanel from "./FundraisingDetailsTabPanel";
import FundraisingDocumentActivity from "./FundraisingDocumentActivity";
import FundraisingNotificationsConfig from "./FundraisingNotificationsConfig";
import FundraisingOverview from "./FundraisingOverview";

const FundraisingDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { hasPermissions } = useClientContext();
  const [dialogState, setDialogState] = useState<DialogState>({});

  const [selectedTab, setSelectedTab] = useState<DetailsPageTab>(
    parseSearchParamWithFallback(searchParams, "tab", detailsPageTabs)
  );

  const getFundraisingDetails = useCallback(
    async () => (id ? adminApi.getFundraisingById(id) : Promise.resolve(createApiResponse(undefined))),
    [id]
  );

  const [
    fundraising,
    fetchFundraisingError,
    { isFetching: isFetchingFundraising, setData: setFundraising, fetch: refreshFundraising },
  ] = useFetch(getFundraisingDetails);

  const [accessCategories, fetchCategoriesError, { isFetching: isFetchingCategories, fetch: refreshCategories }] =
    useFetch(adminApi.getAccessCategories);

  const [portalSettings, fetchSettingsError, { isFetching: isFetchingSettings }] = useFetch(
    adminApi.getInvestorPortalSettings
  );

  if (!id) {
    return <Navigate to="/" />;
  }

  const fetchError = fetchFundraisingError || fetchCategoriesError || fetchSettingsError;
  const isLoading = isFetchingFundraising || isFetchingCategories || isFetchingSettings;

  if (fetchError) {
    logError(fetchError, "[FundraisingDetailsPage] fetch");
    return <DataLoadingFailed title="Failed to load fundraising details" />;
  }

  if (isLoading || fundraising === undefined || accessCategories === undefined) {
    return <InlineLoader />;
  }

  const handleTabChange = (tab: DetailsPageTab) => {
    setSelectedTab(tab);
    setSearchParams({ tab });
  };

  const handleRename = () => {
    if (fundraising) {
      setDialogState({
        openDialog: "rename",
        fundraisingId: fundraising.id,
        fundraisingName: fundraising.name,
      });
    }
  };

  const handleStatusChange = (statusTransition: FundraisingStatusTransition) => {
    if (statusTransition.to === "Live" && !fundraising.hasCapacityToBePublishedToLive) {
      setDialogState({ openDialog: "publish_to_live_restricted" });
      return;
    }

    setDialogState({
      openDialog: "change_status",
      statusTransition,
      fundraisingId: fundraising.id,
      fundraisingName: fundraising.name,
      fundId: fundraising.fund?.id,
      areNotificationsSetUp: fundraising.areNotificationsSetUp,
    });
  };

  const handleConfirmSave = (onSaveConfirmed: () => void) => {
    if (fundraising.status !== "Live") {
      onSaveConfirmed();
      return;
    }

    setDialogState({
      openDialog: "confirm_save",
      onSaveConfirmed,
      fundraisingId: fundraising.id,
      fundraisingName: fundraising.name,
    });
  };

  const handleDelete = () => {
    setDialogState({
      openDialog: "delete",
      fundraisingId: fundraising.id,
      fundraisingName: fundraising.name,
    });
  };

  const handleDialogClose = () => setDialogState({});

  const handleDeleted = () => {
    handleDialogClose();
    navigate("..");
  };

  const handleUpdated = (updatedFundraising: Fundraising) => {
    handleDialogClose();
    setFundraising(updatedFundraising);
  };

  const hasEditPermissions = hasPermissions(["ManageFundraisings"]);

  const fundraisingCategories = accessCategories
    .filter((c) => c.type === "Fundraising")
    .sort(numberComparerBy((c) => c.sortOrder));

  const isPageDeactivatedOnPortal =
    portalSettings !== undefined && !portalSettings.settings.enabledFeatures?.includes("Fundraising");

  const displayedTabs =
    fundraising.status !== "Draft" ? [...detailsPageTabs] : detailsPageTabs.filter((tab) => tab !== "activity");

  const tabLabels =
    fundraising.status !== "Draft"
      ? [...detailsPageTabLabels]
      : detailsPageTabLabels.filter((label) => label !== "Activity");

  return (
    <FundraisingDetailsPageContextProvider
      fundraising={fundraising}
      hasEditPermissions={hasEditPermissions}
      isContentEditable={hasEditPermissions && fundraising.status !== "Completed"}
      allCategories={accessCategories}
      fundraisingCategories={fundraisingCategories}
      isPageDeactivatedOnPortal={isPageDeactivatedOnPortal}
      onRename={handleRename}
      onStatusChange={handleStatusChange}
      onConfirmSave={handleConfirmSave}
      onDelete={handleDelete}
      onTabChange={handleTabChange}
      onUpdated={handleUpdated}
      refreshCategories={refreshCategories}
      refreshFundraising={refreshFundraising}
    >
      <FundraisingDetailsPageHeader />
      <PageTabs tabs={displayedTabs} labels={tabLabels} value={selectedTab} onChange={handleTabChange}>
        <FundraisingDetailsTabPanel value="overview" containerWidth="lg">
          <FundraisingOverview />
        </FundraisingDetailsTabPanel>
        <FundraisingDetailsTabPanel value="categories" containerWidth="lg">
          <FundraisingCategoriesConfig />
        </FundraisingDetailsTabPanel>
        <FundraisingDetailsTabPanel value="access" containerWidth="lg">
          <FundraisingAccessConfig />
        </FundraisingDetailsTabPanel>
        <FundraisingDetailsTabPanel value="content" containerWidth="lg">
          <FundraisingContentConfig />
        </FundraisingDetailsTabPanel>
        <FundraisingDetailsTabPanel value="documents" containerWidth="xl">
          <FundraisingDocuments />
        </FundraisingDetailsTabPanel>
        {fundraising.status !== "Draft" && (
          <FundraisingDetailsTabPanel value="activity">
            <FundraisingDocumentActivity />
          </FundraisingDetailsTabPanel>
        )}
        <FundraisingDetailsTabPanel value="notifications" containerWidth="lg">
          <FundraisingNotificationsConfig />
        </FundraisingDetailsTabPanel>
      </PageTabs>

      <FundraisingDialogs
        dialogState={dialogState}
        onClose={handleDialogClose}
        onCreated={() => handleDialogClose()}
        onUpdated={handleUpdated}
        onDeleted={handleDeleted}
      />
    </FundraisingDetailsPageContextProvider>
  );
};

export default FundraisingDetailsPage;
