import StatusChip from "../../../../shared/components/StatusChip";
import { DataCollectionStatus } from "../../../api/mock/data-collections/dataCollectionsTypes";

interface Props {
  status: DataCollectionStatus;
}

const DataCollectionStatusTag = ({ status }: Props) => {
  switch (status) {
    case "Pending": {
      return <StatusChip label="Pending" withDot color={(t) => t.palette.text.secondary} />;
    }
    case "PendingApproval":
      return <StatusChip label="Pending Approval" withDot color={(t) => t.palette.warning.main} />;
    case "Completed":
      return <StatusChip label="Completed" withDot color={(t) => t.palette.success.main} />;
    default:
      return null;
  }
};

export default DataCollectionStatusTag;
