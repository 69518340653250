import { parseISO } from "date-fns";
import { autoFormatCamelCase } from "../../../../shared/utilities/stringHelper";
import { DataCollection } from "../../../api/mock/data-collections/dataCollectionsTypes";
import { SearchFilterDefinition, TableFilterDefinition } from "../../common/filters/filterTypes";
import { dataCollectionsTypeCaptionMap } from "./dataCollectionsPageTypes";

export const getFilterDefinitions = (): TableFilterDefinition<DataCollection>[] => [
  {
    id: "number",
    name: "Number",
    type: "text",
    getFieldValue: (row) => row.number,
  },
  {
    id: "portfolioCompanyName",
    name: "Portfolio Company",
    type: "text",
    getFieldValue: (row) => row.portfolioCompanyName,
  },
  {
    id: "status",
    name: "Status",
    type: "multi_select",
    getFieldValue: (row) => autoFormatCamelCase(row.status),
  },
  {
    id: "type",
    name: "Type",
    type: "multi_select",
    getFieldValue: (row) => dataCollectionsTypeCaptionMap[row.type],
  },
  {
    id: "reportingDate",
    name: "Reporting Date",
    type: "date",
    getFieldValue: (row) => parseISO(row.reportingDate),
  },
  {
    id: "dueDate",
    name: "Due Date",
    type: "date",
    getFieldValue: (row) => parseISO(row.dueDate),
  },
  {
    id: "submittedByUserName",
    name: "Submitted by",
    type: "text",
    getFieldValue: (row) => row.submittedByUserName,
  },
  {
    id: "submittedAt",
    name: "Submitted at",
    type: "date",
    getFieldValue: (row) => (row.submittedAt ? parseISO(row.submittedAt) : undefined),
  },
  {
    id: "reviewedByUserName",
    name: "Reviewed by",
    type: "text",
    getFieldValue: (row) => row.reviewedByUserName,
  },
  {
    id: "reviewedAt",
    name: "Reviewed at",
    type: "date",
    getFieldValue: (row) => (row.reviewedAt ? parseISO(row.reviewedAt) : undefined),
  },
];

export const getSearchDefinition = (): SearchFilterDefinition<DataCollection> => ({
  getFieldValues: (row) => [row.number, row.portfolioCompanyName, row.submittedByUserName, row.reviewedByUserName],
});
