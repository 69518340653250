import DataGrid from "../../../../shared/components/grid/DataGrid";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import { DataCollection } from "../../../api/mock/data-collections/dataCollectionsTypes";
import { columnDefinitions } from "./dataCollectionsGridDataProvider";

interface Props {
  dataCollections: DataCollection[];
  isLoading?: boolean;
}

const DataCollectionsGrid = ({ dataCollections, isLoading }: Props) => {
  return (
    <DataGrid<DataCollection>
      rows={dataCollections}
      columns={columnDefinitions}
      loading={isLoading}
      rowHeight={40}
      hideFooter
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
      disableColumnReorder
      disableColumnSorting
      disableRowSelectionOnClick
      slots={{
        loadingOverlay: () => <InlineLoader />,
      }}
    />
  );
};

export default DataCollectionsGrid;
