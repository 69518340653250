import { GridColDef } from "@mui/x-data-grid-premium";
import { convertISODate, convertISODateTime } from "../../../../shared/utilities/dateUtils";
import { DataCollection, DataCollectionType } from "../../../api/mock/data-collections/dataCollectionsTypes";
import DataCollectionStatusTag from "./DataCollectionStatusTag";
import { dataCollectionsTypeCaptionMap } from "./dataCollectionsPageTypes";

export const columnDefinitions: GridColDef<DataCollection>[] = [
  {
    field: "number",
    headerName: "Number",
    width: 90,
  },
  {
    field: "portfolioCompanyName",
    headerName: "Portfolio Company",
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: ({ row }) => <DataCollectionStatusTag status={row.status} />,
  },
  {
    field: "type",
    headerName: "Type",
    flex: 1,
    valueFormatter: (value: DataCollectionType) => dataCollectionsTypeCaptionMap[value],
  },
  {
    field: "reportingDate",
    headerName: "Reporting Date",
    flex: 1,
    minWidth: 120,
    valueFormatter: (value: string) => convertISODate(value),
  },
  {
    field: "dueDate",
    headerName: "Due Date",
    flex: 1,
    minWidth: 120,
    valueFormatter: (value: string) => convertISODate(value),
  },
  {
    field: "submittedByUserName",
    headerName: "Submitted by",
    flex: 1,
  },
  {
    field: "submittedAt",
    headerName: "Submitted at",
    flex: 1,
    valueFormatter: (value: string | undefined) => convertISODateTime(value),
  },
  {
    field: "reviewedByUserName",
    headerName: "Reviewed by",
    flex: 1,
  },
  {
    field: "reviewedAt",
    headerName: "Reviewed at",
    flex: 1,
    valueFormatter: (value: string | undefined) => convertISODateTime(value),
  },
];
