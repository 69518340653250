import { Fundraising } from "../../../../../api/types/fundraisingTypes";
import { DialogState } from "../fundraisingsPageTypes";
import ChangeFundraisingStatusDialog from "./ChangeFundraisingStatusDialog";
import ConfirmSaveFundraisingDialog from "./ConfirmSaveFundraisingDialog";
import DeleteFundraisingDialog from "./DeleteFundraisingDialog";
import EditFundraisingDialog from "./EditFundraisingDialog";
import PublishToLiveRestrictedDialog from "./PublishToLiveRestrictedDialog";

interface Props {
  dialogState: DialogState;
  onCreated: (fundraising: Fundraising) => void;
  onUpdated: (fundraising: Fundraising) => void;
  onDeleted: () => void;
  onClose: () => void;
}

const FundraisingDialogs = ({ dialogState, onCreated, onUpdated, onDeleted, onClose }: Props) => {
  return (
    <>
      {dialogState.openDialog === "create" && (
        <EditFundraisingDialog onCreated={onCreated} onUpdated={onUpdated} onClose={onClose} />
      )}

      {dialogState.openDialog === "rename" && dialogState.fundraisingId && dialogState.fundraisingName && (
        <EditFundraisingDialog
          fundraisingId={dialogState.fundraisingId}
          fundraisingName={dialogState.fundraisingName}
          onCreated={onCreated}
          onUpdated={onUpdated}
          onClose={onClose}
        />
      )}

      {dialogState.openDialog === "change_status" && dialogState.statusTransition && dialogState.fundraisingId && (
        <ChangeFundraisingStatusDialog
          fundraisingId={dialogState.fundraisingId}
          fundId={dialogState.fundId}
          statusTransition={dialogState.statusTransition}
          areNotificationsSetUp={dialogState.areNotificationsSetUp}
          onUpdated={onUpdated}
          onClose={onClose}
        />
      )}

      {dialogState.openDialog === "delete" && dialogState.fundraisingId && dialogState.fundraisingName && (
        <DeleteFundraisingDialog
          fundraisingId={dialogState.fundraisingId}
          fundraisingName={dialogState.fundraisingName}
          onDeleted={onDeleted}
          onClose={onClose}
        />
      )}

      {dialogState.openDialog === "confirm_save" && dialogState.onSaveConfirmed && (
        <ConfirmSaveFundraisingDialog
          onSaveConfirmed={() => {
            onClose();
            dialogState.onSaveConfirmed?.();
          }}
          onClose={onClose}
        />
      )}

      {dialogState.openDialog === "publish_to_live_restricted" && <PublishToLiveRestrictedDialog onClose={onClose} />}
    </>
  );
};

export default FundraisingDialogs;
