import { Typography } from "@mui/material";
import { useState } from "react";
import CenteredWrapper from "../../../../../../../shared/components/CenteredWrapper";
import DataGrid from "../../../../../../../shared/components/grid/DataGrid";
import { useCustomDataGridHeaderToolbar } from "../../../../../common/grid/CustomDataGridHeaderToolbar";
import { useFundraisingDocumentsContext } from "./FundraisingDocumentsContext";
import { columnDefinitions } from "./fundraisingDocumentsGridDataProvider";
import FundraisingDocumentsGridToolbar from "./FundraisingDocumentsGridToolbar";
import { FundraisingDocument } from "./fundraisingDocumentsState";

const FundraisingDocumentsGrid = () => {
  const { filteredDocuments, documentsState } = useFundraisingDocumentsContext();
  const { getGridSx } = useCustomDataGridHeaderToolbar();
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

  const showToolbar = selectedRowIds.length > 0;

  return (
    <DataGrid<FundraisingDocument>
      getRowId={(row) => row.fileId}
      rows={filteredDocuments}
      columns={columnDefinitions}
      rowHeight={40}
      sx={() => getGridSx(showToolbar)}
      slots={{
        noRowsOverlay: () => (
          <CenteredWrapper>
            <Typography color="text.secondary">No files yet</Typography>
          </CenteredWrapper>
        ),
        toolbar: () =>
          showToolbar ? (
            <FundraisingDocumentsGridToolbar
              selectedIds={selectedRowIds}
              setSelectedIds={setSelectedRowIds}
              disabled={documentsState.isSaving}
            />
          ) : null,
      }}
      checkboxSelection
      rowSelectionModel={selectedRowIds}
      onRowSelectionModelChange={(selection) => setSelectedRowIds(selection as string[])}
      isRowSelectable={({ row }) =>
        row.uploadStatus === undefined || row.uploadStatus === "error" || row.uploadStatus === "ready_for_upload"
      }
      hideFooter
      disableColumnFilter
      disableColumnMenu
      disableColumnPinning
      disableColumnReorder
      disableColumnSelector
      disableColumnSorting
      disableRowSelectionOnClick
    />
  );
};

export default FundraisingDocumentsGrid;
