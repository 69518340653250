import GroupCollapseIcon from "@mui/icons-material/ArrowDropDownRounded";
import GroupExpandIcon from "@mui/icons-material/ArrowRightRounded";
import { Typography } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import CenteredWrapper from "../../../../../shared/components/CenteredWrapper";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import PreviewFileDialog from "../../../../../shared/components/previewFile/PreviewFileDialog";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import { FileDownloadInfo } from "../../../../api/adminApi";
import { DocumentViews } from "../../../../api/types/documentActivityTypes";
import { getDocumentViewFileDownloadInfo } from "./documentViewFileHelper";
import { DocumentViewsGridContextProvider } from "./DocumentViewsGridContext";
import { columnDefinitions } from "./documentViewsGridDataProvider";
import DocumentViewsGridRowDetail from "./DocumentViewsGridRowDetail";

interface Props {
  isLoading: boolean;
  rows: DocumentViews[];
  onRowsScrollEnd: () => void;
}

const getFileDownloadInfo = withErrorHandling(getDocumentViewFileDownloadInfo);

const DocumentViewsGrid = ({ isLoading, rows, onRowsScrollEnd }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const gridApiRef = useGridApiRef();

  const [previewFileInfo, setPreviewFileInfo] = useState<FileDownloadInfo>();

  const handleShowFilePreview = async (documentView: DocumentViews) => {
    const [fileInfo, error] = await getFileDownloadInfo(documentView);
    if (error?.type === "NotFound") {
      sendNotification(
        "The file you are trying to view is no longer available. It was previously accessible, but it has now been removed."
      );
      return;
    } else if (error) {
      sendNotificationError("Could not get file download information");
      logError(error, `[DocumentViewsGrid] getDocumentViewFileDownloadInfo ${documentView.id}`);
      return;
    }

    setPreviewFileInfo(fileInfo);
  };

  return (
    <DocumentViewsGridContextProvider showFilePreview={handleShowFilePreview}>
      <DataGrid<DocumentViews>
        apiRef={gridApiRef}
        rows={rows}
        columns={columnDefinitions}
        loading={isLoading}
        columnHeaderHeight={36}
        rowHeight={52}
        onRowsScrollEnd={onRowsScrollEnd}
        getDetailPanelContent={({ row }) =>
          Object.values(row.pageViews).some((val) => val > 0) ? <DocumentViewsGridRowDetail row={row} /> : undefined
        }
        getDetailPanelHeight={() => 320}
        slots={{
          loadingOverlay: () => <InlineLoader />,
          noRowsOverlay: () => (
            <CenteredWrapper>
              <Typography color="text.secondary">No document activity</Typography>
            </CenteredWrapper>
          ),
          detailPanelExpandIcon: GroupExpandIcon,
          detailPanelCollapseIcon: GroupCollapseIcon,
        }}
        hideFooter
        disableColumnFilter
        disableColumnSelector
        disableColumnSorting
        disableColumnMenu
        disableColumnReorder
        disableRowSelectionOnClick
      />

      {previewFileInfo?.downloadUrl && (
        <PreviewFileDialog
          url={previewFileInfo.downloadUrl}
          fileName={previewFileInfo.fileName ?? ""}
          onClose={() => setPreviewFileInfo(undefined)}
        />
      )}
    </DocumentViewsGridContextProvider>
  );
};

export default DocumentViewsGrid;
